<template lang="pug">
form.content(autocomplete="off", @submit="onSubmit")
  .section-header
    p Wie heißt der Veranstalter?
    .section-divider
  label.label Öffentlicher Name *
  UiTextInput(
    style-ids="rounded-border hover-primary",
    v-model="organizer.publicName",
    :validation-rules="{ required: true }",
    name="Öffentlicher Name"
  )
  label.label Geschäftlicher Name *
  UiTextInput(
    style-ids="rounded-border hover-primary",
    v-model="organizer.name",
    :validation-rules="{ required: true }",
    name="Geschäftlicher Name"
  )
  .section-header
    p Wie ist der Veranstalter erreichbar?
    .section-divider
  label.label E-Mail
  UiTextInput.relative(
    style-ids="rounded-border hover-primary",
    v-model="organizer.eMail",
    :validation-rules="{ required: false, email: true }",
    autocomplete="chrome-off",
    name="E-Mail"
  )
    template(
      #icon
      v-if="mode === 'UPDATE' && !isDirty && organizer.OrganizerUser_aggregate && organizer.OrganizerUser_aggregate.aggregate.count > 0"
    )
      .icon
        button.flex.items-center.justify-center(
          type="button"
          @click="resendInvitationMail"
        )
          img.resend-email-icon(
            src="../../../assets/icons/paper-plane-solid.svg",
            alt="Zugangsdaten erneut senden"
          )
        .tooltip
          p.tip Zugangsdaten erneut senden

  label.label Zuständiger Mitarbeiter
  UiSelect(
    placeholder="Mitarbeiter auswählen",
    v-model="organizer.supervisorId",
    style-ids="hover-primary"
  )
    option(v-for="admin in admins", :value="admin.id", :key="admin.id") {{ `${admin.firstName} ${admin.lastName}` }}
  label.label Kontaktinformation
  UiTextInput(
    style-ids="rounded-border hover-primary",
    v-model="organizer.contact",
    name="Kontaktinformationen"
  )
  label.label Verwaltet Account eigenständig
  UiSwitch(v-model="organizer.selfManaged")
  UiButton.create-organizer-button(
    v-if="mode === 'UPDATE' && props.organizer?.eMail && (props.organizer?.OrganizerUser_aggregate && props.organizer?.OrganizerUser_aggregate.aggregate.count === 0)"
    type="button",
    style-ids="action",
    @click="modalType = 'invite'; toggleModal()",
    :disabled="isDirty || loading"
  ) Veranstalter-Account erstellen und Einladungsmail schicken
  .section-header
    p Wo befindet sich der Veranstalter?
    .section-divider
  label.label Adresse
  UiLocationAutoComplete(
    style-ids="rounded-border hover-primary",
    :locationAlias="organizer.locationAlias",
    @onSelect="onLocationSelect",
    :precise-address-required="true",
    name="Adresse",
    fetch-policy="address-only",
    autocomplete="chrome-off"
  )
  label.label Straße *
  UiTextInput(
    style-ids="rounded-border",
    v-model="organizer.streetName",
    :validation-rules="{ required: true }",
    name="Straße",
    placeholder="Wird automatisch befüllt",
    :disabled="true"
  )
  label.label Hausnummer *
  UiTextInput(
    style-ids="rounded-border",
    v-model="organizer.streetNumber",
    :validation-rules="{ required: true }",
    name="Hausnummer",
    placeholder="Wird automatisch befüllt",
    :disabled="true"
  )
  label.label Stadt *
  UiTextInput(
    style-ids="rounded-border",
    v-model="organizer.city",
    :validation-rules="{ required: true }",
    name="Stadt",
    placeholder="Wird automatisch befüllt",
    :disabled="true"
  )
  label.label Postleitzahl *
  UiTextInput(
    style-ids="rounded-border",
    v-model="organizer.postalCode",
    :validation-rules="{ required: true }",
    name="PLZ",
    placeholder="Wird automatisch befüllt",
    :disabled="true"
  )
.flex.flex-col.items-center.w-full
  UiButton.create-button(
    style-ids="primary",
    @click="onSubmit",
    :disabled="Object.keys(errors).length > 0 || isSubmitting || loading"
  ) {{ mode === "CREATE" ? "Create" : "Update" }}
  UiButton.delete-button(
    v-if="mode !== 'CREATE'",
    @click="modalType = 'delete'; toggleModal()",
    :disabled="isSubmitting || loading"
  ) Veranstalter löschen
UiModal.modal(v-if="showModal")
  template(#header)
    p.modal-title(v-if="modalType === 'delete'") Möchtest du den Veranstalter wirklich löschen?
    p.modal-title(v-if="modalType === 'invite'") Möchtest du den Veranstalter wirklich einladen?
    p.modal-title(v-if="modalType === 'resend'") Möchtest du den Veranstalter wirklich erneut einladen?
  template(#content)
    div
      p.modal-content-text(v-if="modalType === 'delete'") {{ `Bist du dir sicher, dass du ${organizer.publicName} löschen willst? Folgende Effekte sind zu beachten:` }}
      p.modal-content-text(v-if="modalType === 'invite'") {{ `Bist du dir sicher, dass du ${organizer.eMail} einladen willst? Folgende Prozesse werden angestoßen:` }}
      p.modal-content-text(v-if="modalType === 'resend'") {{ `Beim erneuten Senden der Zugangsdaten wird der Account auf die hier angegebene E-Mail-Adresse geändert. Das bedeutet konkret:` }}
      ul.delete-list(v-if="modalType === 'delete'")
        li <b>ALLE</b> Veranstaltungen werden gelöscht
        li <b>ALLE</b> Veranstaltungsreihen werden gelöscht
        li Die Benutzer werden <b>NICHT</b> informiert
        li Die Events verschwinden aus den gespeicherten Events der Benutzer
      ul.list(v-if="modalType === 'invite'")
        li Ein Account für den Veranstalter wird erstellt
        li Der Veranstalter erhält eine Einladungs-E-Mail
        li Der Veranstalter wird als selbstverwaltend markiert
      ul.list(v-if="modalType === 'resend'")
        li Der Veranstalter kann sich ab sofort nur noch mit der neuen E-Mail-Adresse anmelden
        li Der Veranstalter erhält die Einladungs-E-Mail erneut
        li Der Veranstalter muss ein neues Passwort setzen, da ab dem Versand der E-Mail der aktuelle Zugang nicht mehr funktioniert
      br
      p.font-bold(v-if="modalType === 'delete'") Diese Aktion kann nicht rückgängig gemacht werden!
      p(v-if="modalType === 'delete'") {{ `Wenn du den Veranstalter wirklich löschen möchtest, tippe bitte "${organizer.publicName}" zur Bestätigung ein:` }}
      p(v-if="modalType === 'invite'") {{ `Wenn du den Veranstalter wirklich einladen möchtest, tippe bitte "${organizer.eMail}" zur Bestätigung ein:` }}
      p(v-if="modalType === 'resend'") {{ `Wenn du den Veranstalter wirklich erneut einladen möchtest, tippe bitte "${organizer.eMail}" zur Bestätigung ein:` }}
      br
      UiTextInput.modal-content-input(
        style-ids="rounded-border",
        v-model="modalValidationInput",
        autocomplete="off"
      )
  template(#footer)
    .modal-footer
      UiButton.modal-button(
        @click="modalValidationInput = ''; toggleModal()",
        style-ids="subliminal"
      ) Abbrechen
      UiButton(
        @click="onModalActionButton",
        :style-ids="{ highlight: modalType === 'delete', action: modalType === 'invite' || modalType === 'resend' }",
        :disabled="!modalButtonEnabled"
      ) {{ modalType === "delete" ? "Veranstalter löschen" : modalType === "invite" ? "Veranstalter einladen" : "Veranstalter erneut einladen" }}
</template>

<script setup lang="ts">
import {computed, ref, watch} from "vue";
import {useForm} from "vee-validate";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiModal from "../../../ui/Modal/UiModal.vue";
import UiLocationAutoComplete from "../../../ui/LocationAutoComplete/UiLocationAutoComplete.vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import type {Organizer} from "./types";
import {OrganizerFormMode} from "./types";
import type {FormattedPlace} from "../../../ui/LocationAutoComplete/types";
import UiSelect from "../../../ui/Select/UiSelect.vue";
import UiSwitch from "../../../ui/Switch/UiSwitch.vue";
import {useMutation} from "@vue/apollo-composable";
import {resendPasswordMail} from "../Update/queries";
import {useToast} from "vue-toastification";
import {captureException} from "@sentry/vue";
import {useRouter} from "vue-router";
import cloneDeep from "lodash.clonedeep";

const props = withDefaults(
  defineProps<{
    mode?: OrganizerFormMode;
    organizer?: Organizer;
    loading?: boolean;
    admins: Readonly<
      {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      }[]
    >;
  }>(),
  {
    mode: OrganizerFormMode.CREATE,
    loading: false,
  }
);

const emit = defineEmits([
  "onSubmit",
  "onDelete",
  "onInvite",
]);

// mutation
const { mutate: resendPasswordMailMutation } = useMutation(resendPasswordMail, () => ({
  variables: {
    organizerId: organizer.value.id
  },
}));

const { handleSubmit, isSubmitting, errors } = useForm();
const toast = useToast();
const router = useRouter();
// eslint-disable-next-line vue/no-dupe-keys
const organizer = ref<Organizer>({
  AdminUser_aggregate: {aggregate: {count: 0}},
  OrganizerUser: [],
  eMail: "",
  OrganizerUser_aggregate: {aggregate: {count: 0}},
  city: "",
  name: "",
  postalCode: "",
  publicName: "",
  selfManaged: false,
  streetName: "",
  streetNumber: "",
  verified: false,
  supervisorId: "",
});

// initial copy
if (props.organizer) {
  organizer.value = cloneDeep(props.organizer);
}

watch(() => props.organizer, (newOrganizer) => {
  if (newOrganizer) {
    organizer.value = cloneDeep(newOrganizer);
  }
});

const onSubmit = handleSubmit(() => {
    emit("onSubmit", organizer.value);
});

const showModal = ref(false);
const modalValidationInput = ref("");

let modalType = ref<"invite" | "delete" | "resend">("invite");

const isDirty = computed(() => {
  return JSON.stringify(props.organizer) !== JSON.stringify(organizer.value);
});

const modalButtonEnabled = computed(() => {
  if (modalType.value === "delete") {
    return modalValidationInput.value === organizer.value.publicName;
  } else  if (modalType.value === "invite" || modalType.value === "resend") {
    return modalValidationInput.value === organizer.value.eMail;
  }

  return false;
});

function toggleModal() {
  showModal.value = !showModal.value;
}

function resendInvitationMail() {
  if (
      props.mode === OrganizerFormMode.UPDATE &&
      organizer.value.OrganizerUser[0]?.OrganizerAccount.email !== props.organizer?.eMail
  ) {
    modalType.value = "resend";
    toggleModal();
  } else {
    resendPasswordMailMutation()
        .then(() => router.back())
        .catch(e => {
          captureException(e);
          toast.error("Es ist ein unerwarteter Fehler aufgetreten")
        });
  }
}

function onModalActionButton() {
  if (modalType.value === "invite") {
    emit("onInvite");
    modalValidationInput.value = "";
    toggleModal();
  } else if (modalType.value === "delete") {
    emit("onDelete", organizer.value.id);
    modalValidationInput.value = "";
    toggleModal();
  } else {
    resendPasswordMailMutation()
        .then(() => router.back())
        .catch(e => {
          captureException(e);
          toast.error("Es ist ein unerwarteter Fehler aufgetreten")
        });
  }
}

function onLocationSelect(place: FormattedPlace) {
  organizer.value.location = place.location;
  organizer.value.locationAlias = place.locationAlias;
  organizer.value.streetName = place.addressComponents.streetName;
  organizer.value.streetNumber = place.addressComponents.streetNumber;
  organizer.value.city = place.addressComponents.city;
  organizer.value.postalCode = place.addressComponents.postalCode;
}
</script>

<style lang="postcss" scoped>
.content {
  @apply grid grid-flow-row;
  @apply p-4;
  @apply gap-x-24 gap-y-4;
  grid-template-columns: 3fr 8fr;

  & > * {
    @apply self-center;
  }

  .section {
    &-header {
      @apply text-lg font-bold;
      grid-area: auto / 1 / auto / span 2;
    }

    &-divider {
      @apply h-0.5 bg-background-contrast mt-2;
      grid-area: auto / 1 / auto / span 2;
    }
  }
}

.create-button {
  @apply w-11/12;
}
.delete-button {
  @apply text-background-highlight underline;
}

.modal {
  &-title {
    @apply text-xl font-extrabold;
  }
  &-content {
    &-text {
      @apply select-text mb-3;
    }
    &-input {
      @apply w-8/12;
    }
  }
  .delete-list {
    @apply border p-4 pl-8 border-background-highlight bg-background-highlight bg-opacity-40 border-dashed;
    @apply list-decimal;
  }

  .list {
    @apply list-decimal p-4 pl-8;
  }
  &-button {
    @apply mr-4;
  }
  &-footer {
    @apply flex w-full justify-start;
  }

  &:deep & {
    max-width: 50%;
  }
}

.create-organizer-button {
  grid-area: auto / 1 / auto / span 2;
}

.icon {
  &:hover {
    :last-child {
      @apply block;
    }
  }

  .resend-email-icon {
    @apply w-6 h-6 hover:cursor-pointer;
  }
  .tooltip {
    @apply absolute -right-64 top-1 break-words h-8 bg-primary rounded-2xl hidden;
    .tip {
      @apply px-5 py-1 text-background-dark;
    }
  }
}
</style>
